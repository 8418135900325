import { CSSProperties } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export interface Markers {
  svgString: string;
  isCentered: boolean;
  markerColor?: string;
  position: Coordinates;
  markerStyle?: CSSProperties;
  mapPaneName: keyof google.maps.MapPanes;
}

type ListingActivity = {
  viewsCount: number;
  visitsCount: number;
};

export type ListingAddress = {
  fullAddress: string | null;
  country: string | null;
  countrySubd: string;
  countrySecondarySubd: string;
  addressLine1: string;
  addressLine2: string | null;
  locality: string;
  postal1: string | null;
  postal2: null;
  postal3: null;
  subdivision: null;
  unit: string | null;
};

type ListingLocation = {
  accuracy: null;
  longitude: number;
  latitude: number;
  geoId?: string[] | null;
};

type LocationMlsInfo = {
  mlsId: number;
  mlsName: string;
  realEstateBoard: string | null;
  exclusiveListings: boolean | null;
};

type LocationListedBy = {
  mlsAgentId: string;
  mlsAgentName: string;
  mlsAgentEmail: string;
  mlsAgentPhone: string;
  mlsCoagentId: string | null;
  mlsCoagentName: string | null;
  mlsCoagentEmail: string | null;
  mlsCoagentPhone: string | null;
  mlsBrokerId: string;
  mlsBrokerName: string;
  mlsBrokerEmail: string;
  mlsBrokerPhone: string;
};

type ListingPrice = {
  listingDate: string;
  listingPrice: number;
  listingAmountPerSizeUnit: number | null;
  sizeUnit: string;
};

type SalePrice = {
  saleDate: string | null; // date-string
  saleAmount: number | null;
  saleAmountPerSizeUnit: number | null;
  sizeUnit: string;
};

type Sale = {
  saleDate: string;
  saleCashMort: string;
  saleAmount: number;
  saleCode: string;
  saleType: string;
  saleDocType: string;
  saleDocNumber: string;
  saleSize: number;
};

export type ListingLivingSize = {
  value: number;
  unit: 'acre' | 'sqft';
};

export type ListingLivingSpaceSize = {
  value: number;
  unit: 'acre' | 'sqft';
};

export type ListingSummary = {
  listingStatus: string;
  bathsTotal: number | null;
  bathsHalf: number | null;
  beds: number | null;
  roomsTotal: number | null;
  basementInd: boolean | null;
  archStyle: string | null;
  levelsCount: number | null;
  propClass?: string;
  propType: string;
  propertyTypeCategory: PropTypeCategory;
  propSubType?: string | null;
  propSubtype?: string | null;
  livingSpaceSize: ListingLivingSize[];
  lotSize: ListingLivingSpaceSize[];
  onMarketInd: boolean;
  listingDate: string; // date-string
  listingRemarks: string;
  yearBuilt?: string | number;
  daysOnMarket?: number;
  listingMessage: string | null;
};

export type AdditionalDetails = {
  areaName: string;
  airConditioningInd: boolean;
  seniorCommunityInd: boolean;
  attachedPropertyInd: boolean | null;
  leaseConsideredInd: boolean | null;
  fixerUpperInd: boolean;
  foreclosureInd: boolean;
  furnishedInd: boolean;
  golfCourseInd: boolean;
  greenEnergyInd: boolean;
  hoaInd: boolean;
  horsePropertyInd: boolean;
  leaseToOwnInd: boolean;
  newConsructionInd: boolean;
  openHouseInd?: boolean;
  openHouseDate: string | null; // date-string
  openHouseTime: string | null;
  brandedVirtualTour: string | null;
  virtualOpenHouseInd: boolean;
  unBrandedVirtualTour?: string | null;
  unbrandedVirtualTour?: string | null;
  petInd: boolean;
  poolInd: boolean;
  poolType?: string | null;
  sellerFinanceInd: boolean;
  shortSaleInd?: boolean;
  walkScore: number;
  roadType: string | null;
  constructionType: string[] | null;
  exteriorFeatures: string[] | null;
  viewInd?: boolean;
  viewType: string[] | null;
  lotFeatures: string[] | null;
  outdoorLiving: string[] | null;
  garageInd?: boolean;
  waterFrontInd?: boolean;
};

export enum PageTypes {
  Chat = 'Chat_Page',
  Menu = 'Menu_Page',
  Login = 'Login_Page',
  MyHome = 'MyHome_Page',
  Listing = 'Listing_Page',
  MyMarket = 'MyMarket_Page',
  SplashPage = 'Splash_Page',
  MySearch = 'MySearch_Page',
  MyFavorites = 'MyFavorites_Page',
}

export enum Appliance {
  Dishwasher = 'Dishwasher',
  Dryer = 'Dryer',
  Oven = 'Oven',
  Refrigerator = 'Refrigerator',
  Washer = 'Washer',
  Microwave = 'Microwave',
  DoubleOven = 'Double Oven',
}

type InteriorDetails = {
  fireplaceInd: boolean;
  fireplaceCount: number | null;
  interiorFeatures: string[] | null;
  appliancesIncluded: string[] | null;
  flooringType: string[] | null;
  windowFeatures: string[] | null;
  masterOnMainInd?: boolean;
  atticDesc: string;
};

type ParkingDetails = {
  parkingSpaces: number | null;
  attachedGarageInd: boolean | null;
  garageInd: boolean;
  parkingType: string[] | null;
};

type PriceHistoryChange = {
  percentChange?: string | number | null;
  priceChangeDate: string; // date-string
  priceChange: number;
  mlsListingId?: string;
};
type PriceHistory = PriceHistoryChange[];

export type School = {
  schoolDistrict: string | null;
  schoolId?: string | number | null;
  latitude: number | null;
  longitude: number | null;
  schoolName: string | null;
};
export type Schools = {
  [ElementarySchool: string]: School;
  MiddleSchool: School;
  HighSchool: School;
};

type Tax = {
  assessedValue: number | null;
  assessedImprovementsValue: number | null;
  assessedLandValue: number | null;
  assessedSize: number | null;
  marketValue: number | null;
  marketSize: number | null;
  taxAmount: number | null;
  taxSize: string | null;
  taxYear: string | null;
};

type UtilityDetails = {
  waterType: string | null;
  hotWater: string | null;
  airConditioningInd: boolean;
  heatingZonesCount: number | null;
  sewerType: string | null;
  heatingType: string[] | null;
  coolingType: string | null;
};

export type ListingDetails = {
  Valuation: Valuation;
  Sale: Sale;
  mlsListingId: string;
  photosCount: number;
  photoLastUpdate: string | null; // date-string
  primaryThumbnail: string | null;
  lastUpdate?: string; // date-string
  neighborhood?: string;
  geoIds?: string[];
  lotSizeSource?: null;
  propTypeId?: number;
  Activity: ListingActivity;
  Address: ListingAddress;
  Location: ListingLocation;
  MlsInfo: LocationMlsInfo;
  ListedBy: LocationListedBy;
  ListingPrice: ListingPrice;
  SalePrice: SalePrice;
  ListingSummary: ListingSummary;
  PropertySummary: ListingSummary;
  AdditionalDetails: AdditionalDetails;
  Media: string[];
  InteriorDetails: InteriorDetails;
  ParkingDetails: ParkingDetails;
  PriceHistory: PriceHistory;
  Schools: Schools;
  Tax: Tax;
  UtilityDetails: UtilityDetails;
  daysOnMarket?: string | null;
  uuid: string;
  listingType?: string;
  isExcluded?: boolean;
};

export type TDropdownOption = {
  label: string;
  value: string;
  icon?: IconDefinition | JSX.Element;
  hideType?: string[];
  additionalActiveText?: string;
  resultNumber?: number;
};

export type TMinimumRoomOption = number;
export type TRoomsFilterSwitchStatus = 'min' | 'exact';

export type TMapPolygon = google.maps.Polygon | null;

export type TMapDrawingMode = google.maps.drawing.OverlayType | null;

export type TListingDetailsExpandableId =
  | 'overview'
  | 'propertyDetails'
  | 'salesAndTaxHistory'
  | 'schools';

export interface SearchArea {
  areaId: string | null;
  areaType: string | null;
  countrySubd: string | null;
}

export type Area = {
  areaId: string;
  areaName: string | null;
  areaType: string;
  continent: string | null;
  country: string | null;
  countrySecondarySubd: string | null;
  countrySubd: string | null;
  description: string | null;
  locality: string | null;
  mapLocation: string | null;
  neighborhood: string | null;
  polygon: [number, number][];
  polygonId: string;
  postal1: string | null;
  uniqueName?: string | null | undefined;
  isPrimary?: boolean;
};

type SavedSearchArea = {
  areaId: string;
  areaName: string;
  areaType: string;
  countrySubd: string | null;
  trackingId: string | null;
  searchResultDesc: string | null;
  searchResultCount: string | null;
  searchResultAvgPrice: string | null;
  polygonIds: string[];
};

type SearchFilters = {
  beds: number | null;
  bathsFull: number | null;
  bathsPartial: number | null;
  minListPrice: number | null;
  maxListPrice: number | null;
  minLotSize: number | null;
  maxLotSize: number | null;
  minLivingSpace: number | null;
  maxLivingSpace: number | null;
  minYearBuilt: number | null;
  maxYearBuilt: number | null;
  internalOpenHouseInd: boolean;
  soldListingsInd: boolean;
  searchOptions: string;
};

type SearchMeta = {
  searchLastSent: string;
  searchLastRun: string;
  searchCreated: string;
  searchUpdated: string;
  searchDeleted: string | null;
  lastUpdatedBy: string;
  searchFrequency: string;
  emailCc: string | null;
};

export type CoordinatesPair = [number, number];

export type TPolygon = {
  type: string;
  coordinates: CoordinatesPair;
};

export type Polygon = {
  type: 'Polygon' | 'MultiPolygon' | string;
  bbox: CoordinatesPair[];
  coordinates: CoordinatesPair[][];
};

export type GeometryPolygon = {
  geometry: Polygon;
};

export type TPolygonDetails = {
  Geometry: Polygon;
  areaId: string;
  areaType: string;
  polygonId: string;
};

export type PropTypes = { typeId: number; propType: string };

export type SavedSearch = {
  savedSearchId: number;
  searchName: string | null;
  activeInd: boolean;
  mlsId: number;
  SearchArea: SavedSearchArea[];
  Polygon: Polygon[] | null;
  PropTypes: PropTypes[] | number[];
  SearchFilters: SearchFilters;
  SearchMeta: SearchMeta;
  RecommendedListings: string[];
  updateKvCoreInd?: boolean;
};

export type SearchSuggestion = {
  Address: ListingAddress;
  Area: Area;
  contentType: string | null;
  fieldLabel: string;
  mlsId: string | string[];
  mlsListingId: string;
  recordId: string;
  score: number;
  suggestion: string;
};

export type TListingsSortValue =
  | 'listDateNewest'
  | 'listDateOldest'
  | 'saleDateNewest'
  | 'saleDateOldest'
  | 'priceHighest'
  | 'priceLowest'
  | 'bedCountHighest'
  | 'sqftHighest'
  | 'bathCountHighest';

type MlsRules = {
  courtesyFormat: string;
  courtesyOnResults: number;
  courtesyOnTop: boolean;
  displayLimit: number | null;
  hideAgentInfo: boolean;
  hideDom: boolean;
  hideGrid: boolean;
  hideMortgage: boolean;
  hideOverlays: boolean;
  hidePopularity: boolean;
  hidePriceChanges: boolean;
  hidePrint: boolean;
  hideReductions: boolean;
  hideShowMore: boolean;
  hideSize: boolean;
  hideYearBuilt: boolean;
  labelStatistics: boolean;
  logoInResults: boolean;
  maxResults: number | null;
  noImageCrop: boolean;
  noMlsUse: boolean;
  noPriceAbbeviation: boolean;
  showSizeRange: boolean;
  hideSoldData: boolean;
};

export type MlsDetails = {
  mlsId: number;
  mlsName: string;
  mlsLogo: string;
  thumbnailLogoWidth: number;
  activeMls: boolean;
  justListed: boolean;
  mlsDisclaimer: string;
  mlsDisclosureColor: string | null;
  multipleMLS: string | null;
  refreshDate: string;
  mlsRules: MlsRules;
};

type TIpLocation = {
  calling_code: string;
  capital: string;
  country_flag: string;
  country_flag_emoji: string;
  country_flag_emoji_unicode: string;
  geoname_id: number;
  is_eu: boolean;
};

type TIpLanguage = {
  code: string;
  name: string;
  native: string;
};

export type TUserIpData = {
  city: string;
  continent_code: string;
  continent_name: string;
  country_code: string;
  country_name: string;
  ip: string;
  latitude: number;
  location: TIpLocation;
  languages: TIpLanguage[];
  longitude: number;
  region_code: string;
  region_name: string;
  type: string;
  zip: string;
};

export type TUserLocation = {
  areaId: string;
  areaType: string;
  countrySubd: string;
};

export type TSaleType = 'sale' | 'rent' | 'sold';

export type TSaleTypeOption = {
  label: string;
  value: TSaleType;
  options?: (TDropdownOption & { isDefault?: boolean })[];
};
export interface ListingSnapshotQuery {
  isLoading?: boolean;
  isFetching?: boolean;
  noOfLoaded: number;
  total?: number;
  data?: ListingDetails[];
}

export enum Size {
  Xs = 'xs',
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
  Xl = 'xl',
}

export type Filter = (
  listings: ListingDetails,
  value: any, // eslint-disable-line
  allFilterValues: any, // eslint-disable-line
) => boolean;

export type TAdditionalFilterType = 'preferences' | 'requirements';

export type TAdditionalFilterTypeOption = {
  label: string;
  value: TAdditionalFilterType;
};

export type TAdditionalFilter =
  | 'New Construction Only'
  | 'No HOA Fees'
  | 'View'
  | 'Pool'
  | 'Waterfront'
  | 'Furnished'
  | 'Basement'
  | 'Golf Course';

export enum PropTypeFilter {
  SingleFamily = 'singleFamily',
  Condo = 'condo',
  MultiFamily = 'multiFamily',
  Land = 'land',
  Commercial = 'commercial',
}
export type TSaleListingStatusValue =
  | 'all'
  | 'comingSoonAndActive'
  | 'active'
  | 'comingSoon'
  | 'activeAndUnderContractPending'
  | 'underContractPending';

export type TAreaCoordinates =
  | google.maps.MVCArray<google.maps.LatLng>
  | google.maps.LatLng[]
  | google.maps.LatLng[][]
  | google.maps.LatLngLiteral[];

export type THighlightedType =
  | 'all'
  | 'newListings'
  | 'priceReduced'
  | 'openHouse'
  | '3dTour';

export type SaleParam = {
  type: TSaleType;
  option?: TSaleListingStatusValue | string;
};

export type TRoomPredictionType = 'more' | 'less';

export type TRoomPrediction = {
  predictionType: TRoomPredictionType;
  num: number;
};
export interface TViewedListing {
  lastViewedDate: string | null;
  listingComments: string | null;
  listingDeletedDate: string | null;
  listingViewId: number;
  mlsId: number;
  mlsListingId: string;
  mobileViewsCount: number | null;
  saveInd: boolean | null;
  todaysViewsCount: boolean | null;
  viewsCount: number | null;
  weeksViewsCount: number | null;
}

export type TListingDetailsParams = {
  mlsId: string;
  mlsListingId: string;
};

export type TListingFeatures = {
  airConditioningInd: number;
  basementInd: number;
  deckInd: number;
  fireplaceInd: number;
  fixerUpperInd: number;
  // foreclosureInd: number;
  furnishedInd: number;
  garageInd: number;
  golfCourseInd: number;
  greenEnergyInd: number;
  horsePropertyInd: number;
  newConstructionId: number;
  petInd: number;
  poolInd: number;
  sellerFinanceInd: number;
  seniorCommunityInd: number;
  shortSaleInd: number;
  viewInd: number;
};

export type THomesFilterValue =
  | 'all'
  | 'active'
  | 'priceReduced'
  | 'openHouse'
  | 'favorites';

export type TNextHomeFilterValue =
  | 'all'
  | 'priceReduced'
  | 'openHouse'
  | 'rental';

export interface AccountDetails {
  feature: string;
  enabledInd: boolean;
  accountId: string;
  clientConfig: ClientConfig;
  isGeneric: boolean;
}

interface ClientConfig {
  aws: Aws;
  awsCognitoUserPoolId: string;
}

export interface Aws {
  awsProjectRegion: string;
  awsCognitoIdentityPoolId: string;
  awsCognitoRegion: string;
  awsUserPoolsWebClientId: string;
  oauth: Oauth;
  federationTarget: string;
  sourceCognitoClientAppId: string;
}

interface Oauth {
  domain: string;
  scope: string[];
  responseType: string;
}

export type PriceScale = {
  prices: number[];
  distributions: number[];
};

export type TSaveSearchProps = {
  searchName: string;
  savedSearchId?: number | undefined;
};

export interface TPropertyFilterButton<T> {
  label: string;
  value: T;
  resultNumber: number;
  icon: IconDefinition | null;
  imageIcon?: JSX.Element;
}

export interface RQBaseOptions {
  enabled?: boolean;
}

export interface Meta {
  appProfileId?: string | number;
  mlsId?: string | number;
  agentId: string;
  agentChatUserId: string;
  familyId?: string | number;
  birthday?: string | Date;
  gender?: string;
  agentDomain: string;
  ipAddress: string;
  preferredPronoun?: string;
  marketStatsLastUpdate?: string;
  addressVerified: boolean;
  accountName: string;
  accountId: number;
  ownsPropertyInd?: boolean;
  lookingToRentInd?: boolean;
}

export interface Name {
  firstName: string;
  lastName: string;
  middleName?: string;
  suffixName?: string;
  nickName?: string;
  titleName?: string;
}

export interface Communication {
  emailPrimary: string;
  emailSecondary?: string;
  phoneHome: string;
  phoneWork?: string | number;
  phoneMobile: string;
  phoneSpouse?: string | number;
  emailOptIn: boolean;
  emailOptInDate: Date;
  textOptIn: boolean;
  textOptInDate: Date;
  phoneOptIn: boolean;
  phoneOptInDate: Date;
}

export interface Address {
  fullAddress?: string | null;
  country?: string | null;
  countrySubd: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  countrySecondarySubd?: string | null;
  subdivision?: string | null;
  locality: string | null;
  postal1: string | null;
  postal2?: string | number | null;
  postal3?: string | number | null;
}

export interface OptInPreference {
  optInType: string;
  optIn?: boolean;
  optInDate: Date | string;
}

export interface FavoriteListing {
  favoriteListingId: number;
  mlsId: number;
  mlsListingId: string;
  favoriteStatus: string;
  favoriteStatusDate: string;
  mobileInd: boolean;
  lastViewedDate: Date;
  listingComments: string;
}

export interface AreasOfInterest extends Area {
  areasOfInterestId: number;
  isPrimary: boolean;
}
export interface AgentCoverage {
  underCoverage: boolean;
  reason: string;
}
export interface IHomeownerDetail {
  homeownerId: number;
  homeownerUuid: string;
  myPropertyUuid: string;
  contactId: number;
  chatUserId: string;
  Meta: Meta;
  Name: Name;
  Communication: Communication;
  Address: Address;
  OptInPreferences: OptInPreference[];
  FavoriteListings: FavoriteListing[];
  AreasOfInterest: AreasOfInterest[];
  Media: [];
  homeownerKey: string;
  AgentCoverage: AgentCoverage;
}

export interface BrandingDetails {
  googleCode: null;
  facebookCode: null;
  defaultSort: string;
  askUnit: boolean;
  showNeighborhood: boolean;
  customHeader: null;
  customFooter: null;
  customChat: null;
  customBody: null;
  recruitingText: null;
  limitCoverage: boolean;
  searchOptions: null;
  requirePhone: boolean;
  showInactiveAgents: boolean;
  hideRoster: boolean;
  maxViews: number;
  mlsList: number[];
  areasServicedList: string[];
  backgroundImages: string[];
  siteTemplate: string;
  siteTheme: string;
  AdditionalColors: AdditionalColors;
  siteOptions: null;
  propertyTypes: number[];
  defaultTowns: null;
  facebookId: null;
  siteTitle: null;
  showAgentBlogs: boolean;
  noRealtorFlag: boolean;
  agentTaglineFlag: boolean;
  useAgentTagline: boolean;
  alternateSellerPage: null;
  requireSellerLogin: boolean;
  displayOfficePhone: boolean;
  metaDescription: null;
  searchLabel: string;
  sellLabel: string;
  financeLabel: string;
  resourcesLabel: string;
  agentsLabel: string;
  recruitingLabel: string;
  recruitingLink: string;
  hideResourcesLabel: boolean;
  hideFinanceLabel: boolean;
  recruitingLinkFlag: boolean;
  voicepadNumber: null;
  hidePrint: boolean;
  drivetimeFlag: boolean;
  zillowEmail: null;
  zillowScreenName: null;
  hideOfficeInfo: boolean;
  childTestimonialsFlag: boolean;
  additionalAddress: null;
  hideMillionsMapped: boolean;
  additionalLogo: null;
  hideAgent: boolean;
  forceRegistration: boolean;
  siteTagline: null;
  ccpaPhone: string;
  ccpaEmail: string;
  localLogicFlag: boolean;
  hideWcag: boolean;
  overrideLogo: boolean;
  hideLabels: boolean;
  hideZillowTestimonials: boolean;
  usePolygons: boolean;
  localizationKey: null;
  defaultLanguage: string;
  awardsList: string[];
  selectLanguage: boolean;
  themeColor: string;
  hideManualListings: boolean;
  countiesList: string[];
  areasList: string[];
  disableMillionsMapped: boolean;
  disableWcag: boolean;
  defaultLogo: string;
  soldDataFlag: boolean;
  accountType: string;
  clientId: number;
  ownerId: number;
  parentSite: null;
  parentDomain: string;
  teamSiteFlag: boolean;
  clientState: null;
  clientName: string;
  brokerIdList: string[];
  coreHomeIosAppLink: string;
  coreHomeAndroidAppLink: string;
}

interface AdditionalColors {
  primaryColor: null;
  secondaryColor: null;
  backgroundColor: null;
  brandColor: null;
  mainBlack: null;
  lightBlack: null;
  darkerGray: null;
  darkGray: null;
  mediumGray: null;
  mainGray: null;
  lightGray: null;
}

export type GeoPoint = { lat: number; lon: number };

export enum PinType {
  Sold = 'sold',
  New = 'new',
  Pending = 'pending',
}

export type TPinType = 'sold' | 'new' | 'pending';

export type SimilarHomesListing = ListingDetails & { isExcluded?: boolean };

export type TSimilarHomesCriteria = {
  minBeds: number;
  maxBeds: number;
  minPropertySize: number;
  maxPropertySize: number;
  range: number;
};

export interface ValuationAddress {
  fullAddress: string;
  country: string;
  countrySubd: string;
  countrySecondarySubd: string;
  addressLine1: string;
  addressLine2: string;
  locality: string;
  postal1: string;
  postal2: string;
  postal3: string;
  neighborhood: string;
  subdivision: string;
}
export interface Valuation {
  valueDate: string;
  valueScore: number;
  valueTarget: number;
  valueHigh: number;
  valueLow: number;
  valueRange: number;
  valueSize: number;
  valueLast: number;
  valueChangeAmt: number;
  valueChangePct: number;
  valueSource?: string;
}

export interface ValuationDetails {
  Address: ValuationAddress;
  Valuation: Valuation[];
}

export interface Loan {
  deedType: string | null;
  dueDate: string | null;
  lenderCompany: string;
  loanAmount?: number;
  loanBalance: number | null;
  loanEquity: boolean | null;
  loanId?: number;
  loanInterest: number | null;
  loanInterestType: string | null;
  loanPayment: number | null;
  loanPaymentFrequency: 'weekly' | 'bi-weekly' | 'monthly' | null;
  loanRefinance: boolean | null;
  loanStatus: 'Active' | 'Closed' | 'Deleted' | null;
  loanTerm: number | null;
  loanType: 'Home Equity' | 'Refinance' | 'Primary';
  startDate: string;
  titleCompany: string | null;
}

interface Owner {
  corporateIndicator: boolean;
  firstName: string;
  lastName: string;
}

export interface MortgageDetail {
  Loans: Loan[];
  Owner: Owner;
  homeownerId: number;
  homeownerUuid: string;
  propertyUuid: string;
}
export interface FeatureFlag {
  enabledInd: boolean;
  feature: string;
}

export interface FeatureFlags {
  [index: string]: FeatureFlag;
}

export interface ListingDetailFetchParams {
  areaType: string;
  filterBy: string;
  areaId: string;
  countrySubd: string;
  propTypeId: string;
  listingStatus: string;
  maxResults: string;
  orderBy: string;
  order: string;
}

export enum PropTypeCategory {
  SINGLE_FAMILY = 'SINGLE_FAMILY',
  CONDO = 'CONDO',
  MULTI_UNIT = 'MULTI_UNIT',
}

export type TConversationType =
  | 'chat'
  | 'favorited_listing'
  | 'saved_search'
  | 'email'
  | 'call'
  | 'shared_app';

export interface IPolicy {
  policyId: number;
  parentPolicyId?: unknown;
  isAddOnPolicy: boolean;
  policyType?: unknown;
  policyStatus: string;
  policyStartDate?: unknown;
  policyExpirationDate: string;
  policyPremium: number;
  policyDeductible?: unknown;
  propertyCoverage?: unknown;
  liabilityCoverage?: unknown;
  carrierName: string;
}

export interface IInsuranceDetails {
  homeownerId: number;
  homeownerUuid: string;
  propertyId: number;
  propertyUuid: string;
  Policy: IPolicy[];
}

export enum TooltipPlacements {
  TOP = 'top',
  AUTO = 'auto',
  LEFT = 'left',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  TOP_END = 'top-end',
  AUTO_END = 'auto-end',
  LEFT_END = 'left-end',
  RIGHT_END = 'right-end',
  TOP_START = 'top-start',
  LEFT_START = 'left-start',
  AUTO_START = 'auto-start',
  BOTTOM_END = 'bottom-end',
  RIGHT_START = 'right-start',
  BOTTOM_START = 'bottom-start',
}

export type AddressAndMetaPayload = {
  Address?: Address | null;
  Meta: {
    lookingToRentInd: boolean;
    addressVerified: boolean;
    ownsPropertyInd: boolean;
  };
};

export type FavoriteParams = {
  homeownerDetail?: IHomeownerDetail;
  listingDetail?: ListingDetails | null;
  listingComments?: string | null;
};

export type FavoriteListingPayload = FavoriteParams & {
  status: string;
};

export interface AgentDetails {
  agentId: string;
  accountId: number;
  lenderId: number;
  groupId: null;
  Name: AgentName;
  mlsId: string[];
  AgentCommunication: AgentCommunication;
  Links: Links;
  Office: Office[];
  Bio: Bio;
  Meta: AgentMeta;
  Reviews: Review[];
}

interface AgentCommunication {
  chatEnabled: boolean;
  emailPrimary: string;
  emailSecondary: string;
  phoneDirect: null;
  phoneWork: string;
  phoneMobile: string;
  phoneFax: null;
  useEmailSecondary: boolean;
  showMobile: boolean;
  showWork: boolean;
  showDirect: boolean;
}

interface Bio {
  agentTagline: null;
  agentDescription: string;
  agentBackground: null;
  agentEducation: null;
  agentExperience: null;
  agentAchievements: string[];
  agentCommunity: string[];
  agentPositions: string[];
  agentDesignations: string[];
  agentPrimaryLanguage: string;
  agentAdditionalLanguages: string[];
  agentCoverage: string[];
  agentListings: string;
  agentPhoto: string;
  agentLicenseNumber: string;
}

interface Links {
  companyWebsite: string;
  personalWebsite: null;
  facebookLink: string;
  twitterLink: null;
  linkedinLink: string;
  youtubeLink: null;
  instagramLink: null;
  pinterestLink: null;
  otherLink: null;
  appLink: null;
}

interface AgentMeta {
  agentLastUpdate: Date;
}

interface AgentName {
  firstName: string;
  lastName: string;
  middleName: null;
  suffixName: null;
  nickName: null;
  titleName: null;
}

export interface Office {
  officeName: string;
  Address: Address;
  officePhoto: null | string;
  OfficeHours: OfficeHours;
}

interface OfficeHours {
  monday: Day;
  tuesday: Day;
  wednesday: Day;
  thursday: Day;
  friday: Day;
  saturday: Day;
  sunday: Day;
}

interface Day {
  start: string;
  end: string;
}

interface Review {
  reviewId: number;
  activeStatusInd: boolean;
  reviewRating: number;
  reviewSource: string;
  reviewerName: string;
  reviewDate: Date;
  reviewContent: string;
  websiteId: number;
  languageCode: string;
}

export enum HOP_API_VERSION {
  V1 = 'v1',
  V2 = 'v2',
}

export type WithActive = {
  active: boolean;
};

export type WithIsMobile = {
  isMobile: boolean;
};

export enum MOVE_FEATURE {
  TRACKER = 'TRACKER',
  CHECKLIST = 'CHECKLIST',
  CALCULATOR = 'CALCULATOR',
  REFERRALS = 'REFERRALS',
  DOCUMENTS = 'DOCUMENTS',
  EXPERTISE = 'EXPERTISE',
}
export interface FeatureConfig {
  id: MOVE_FEATURE;
  icon: string;
  text: string;
  width: string;
  label: string;
  height: string;
  sectionImage: string;
}

export interface INavGroupPaths {
  myHome: string;

  myHomeMarket: string;
  myMarkets: string;
  marketReport: string;
  savedHomes?: string;
  marketDetail?: string;
}

export interface NavGroupSearchPath {
  search: string;
  searchResults: string;
  listing?: string;
  listings?: string;
}

export interface NavGroupFavoritesPath {
  favorites: string;
}

export interface NavGroupeNotificationsPath {
  notifications: string;
}

export interface NavGroupJourneyPath {
  move?: string;
  maintenance?: string;
  transactions?: string;
}
export interface Move {
  id?: string;
  address: Address | null;
  endDate: string;
  startDate: string;
  status: string;
  tabs?: MoveTab[];
  checkedTasks: { [id: number]: { id: number; checkDate: Date }[] };
  costEstimate?: CostEstimate;
}

export interface MoveTab {
  id: number;
  end: string;
  start: string;
  endDate: string;
  position: number;
  startDate: string;
  items: MoveTabItem[];
}

export interface MoveTabItem {
  id: number;
  position: number;
  headline: string;
  checkedAt: string;
  description: string;
}

export interface CostEstimate {
  id: string;
  companyName: string;
  licenseNumber: string;
  contactName: string;
  estimateAmount?: number | null;
}

export type Coordinates = { lat: number; lng: number };

export type AddressWithCoordinates = Address & {
  coordinates?: Coordinates;
};

export type DeepPartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? DeepPartial<U>[]
    : T[P] extends readonly (infer U)[]
    ? readonly DeepPartial<U>[]
    : DeepPartial<T[P]>;
};

export enum Layer {
  Default = 0,
  Enhanced = 50,
  Priority = 100,
  HighPriority = 150,
  Critical = 200,
  MustSee = 2000,
}

export type BrandingDetailsExtended = BrandingDetails & {
  themeColorHex: string;
};
