import { useRef } from 'react';
import styled from '@emotion/styled';
import { Font } from '@react-pdf/renderer';

import { Banner } from './Banner/Banner';
import { CornerChat } from '../../../features/Chat';
import SideMenu from '../SideMenu/SideMenuImproved';
import useScrollToTop from '../../../hooks/useScrollToTop';
import { useBanner } from '../../../providers/BannerProvider';
import ToastComponent from '../ToastComponent/ToastComponent';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import { useIsEmbedded } from '../../../hooks/query/useIsEmbeded';
import { AppHeaderNav, MobileFooterNav } from '../../../features/Nav';
import { FEATURES, useHasFeatureFlag } from '../../../hooks/useFeatureFlags';
import { TooltipRoot } from '../InfoTooltip/TooltipRoot';

Font.register({
  family: 'ProximaNova',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/font-proxima-nova@1.0.1/fonts/ProximaNova-Regular.ttf',
      fontWeight: 'normal',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/font-proxima-nova@1.0.1/fonts/ProximaNova-Bold.ttf',
      fontWeight: 'bold',
    },
  ],
});

const ContentWrapper: React.FC = ({ children }) => {
  const { show, isClosed } = useBanner();
  const isMobileView = useIsMobileView();
  const isEmbedded = useIsEmbedded();

  const hideBanner = useHasFeatureFlag(FEATURES.HopHideSmartBanner);

  if (!show || !isMobileView) {
    return <div style={{ flexGrow: 1 }}>{children}</div>;
  }

  let marginTop = isClosed ? 60 : 290;
  let marginBottom = '2rem';
  if (isEmbedded) {
    marginTop = 0;
    marginBottom = '0';
  }

  if (hideBanner) {
    marginTop = 0;
  }

  return (
    <MobileWrapperEl marginTop={marginTop} marginBottom={marginBottom}>
      {children}
    </MobileWrapperEl>
  );
};

const DesktopWrapperEl = styled.div({
  flexGrow: 1,
  display: 'flex',
  paddingBottom: 0,
  flexDirection: 'column',
  justifyContent: 'stretch',
});

const MobileWrapperEl = styled.div<{
  marginTop: string | number;
  marginBottom: string | number;
}>`
  flex-grow: 1;
  display: flex;
  margin-top: ${({ marginTop }) => `${marginTop}px`};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;
const PageWrapper: React.FC = ({ children }) => {
  const refToTop = useRef<HTMLInputElement>(null);
  useScrollToTop(refToTop);
  return <DesktopWrapperEl ref={refToTop}>{children}</DesktopWrapperEl>;
};

export const KnownUserLayout: React.FC = ({ children }) => {
  const isEmbedded = useIsEmbedded();
  const isMobile = useIsMobileView();

  return (
    <PageWrapper>
      <TooltipRoot />
      <ToastComponent />
      {isEmbedded ? null : <Banner />}
      {isEmbedded || isMobile ? null : <AppHeaderNav />}

      <SideMenu />
      <ContentWrapper>{children}</ContentWrapper>
      {isEmbedded ? null : <MobileFooterNav />}
      {isEmbedded ? null : <CornerChat />}
    </PageWrapper>
  );
};
