import { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Switch, Redirect } from 'react-router';

import ErrorPage from '../../error-page';
import PublicRoute from '../../V2/PublicRoute';
import { routerPaths } from '../../../constants';
import { FAQPage } from '../../../pages/FAQ/FAQPage';

import { lazyWithRetry } from '../../../utils/lazyWIthRetry';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import { KnownUserLayout } from '../../V2/layout/KnownUserLayout';
import { useAgentDetails } from '../../../hooks/query/useAgentDetails';
import { useHomeownerByContact } from '../../../hooks/useHomeownerByContact';
import { useChatConversation } from '../../../hooks/query/useChatConversation';
import { FEATURES, useHasFeatureFlag } from '../../../hooks/useFeatureFlags';
import { useResetFeatureInFocusOnPageChange } from '../../../features/Tile';
import { VisitedRoutesProvider } from '../../../features/VisitedRoutes';
import { STATIC_CONFIG } from '../../../constants/config';

const ChatFeatureReminder = lazy(
  () =>
    import('../../../features/Chat/ChatFeatureReminder/ChatFeatureReminder'),
);

const MyHomePage = lazy(() =>
  lazyWithRetry(import('../../../pages/MyHome/MyHome')),
);

const MyMarketsPage = lazy(() =>
  lazyWithRetry(import('../../../pages/MyMarkets/MyMarkets')),
);

const MyHomeMarketPage = lazy(() =>
  lazyWithRetry(import('../../../pages/MyHomeMarket/MyHomeMarket')),
);

const MyMovePage = lazy(() => import('../../../pages/MyMove/MyMove'));

const MyTransactionPage = lazy(
  () => import('../../../pages/MyTransaction/MyTransaction'),
);

const MyMaintenancePage = lazy(
  () => import('../../../pages/MyMaintenance/MyMaintenance'),
);

const MovingInventory = lazy(
  () =>
    import(
      '../../../pages/MyMove/layout/mobile/active/MovingInventory/MovingInventory'
    ),
);

const EssentialQuestions = lazy(() =>
  lazyWithRetry(
    import(
      '../../../pages/MyMove/layout/mobile/active/EssentialQuestions/EssentialQuestions'
    ),
  ),
);

const ListingDetail = lazy(() =>
  lazyWithRetry(import('../../../pages/ListingDetails/ListingDetails')),
);

const PageNotFoundPage = lazy(() =>
  lazyWithRetry(import('../../../pages/Error/PageNotFound/PageNotFound')),
);

const MarketDetailPage = lazy(() =>
  lazyWithRetry(import('../../../pages/MarketDetailPage/MarketDetailPage')),
);

const AboutAgent = lazy(() =>
  lazyWithRetry(import('../../../pages/AboutAgent/AboutAgent')),
);

const Logout = lazy(() =>
  lazyWithRetry(import('../../../pages/logout-page/logout-page')),
);

const Search = lazy(() =>
  lazyWithRetry(import('../../../pages/Search/Search')),
);

const SearchResults = lazy(() =>
  lazyWithRetry(import('../../../pages/SearchResults/SearchResults')),
);

const SavedHomes = lazy(() =>
  lazyWithRetry(import('../../../pages/SavedHomes/SavedHomes')),
);

const NotificationsPage = lazy(() =>
  lazyWithRetry(import('../../../pages/Notifications/NotificationsPage')),
);

export const KnownUserRoutes = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobileView();
  useResetFeatureInFocusOnPageChange();

  useAgentDetails();
  useChatConversation();
  const { isError } = useHomeownerByContact();

  const hasNotificationsFlag = useHasFeatureFlag(
    FEATURES.HopWebNotificationsPanel,
  );

  if (isError) {
    return (
      <ErrorPage
        title={t('appNotAvailablePage.title')}
        subtitle={t('appNotAvailablePage.subtitle')}
        cta={false}
      />
    );
  }

  return (
    <VisitedRoutesProvider>
      <KnownUserLayout>
        <Suspense fallback={<></>}>
          <ChatFeatureReminder />
          <Switch>
            <PublicRoute
              exact
              path={routerPaths.auth.logout}
              component={Logout}
            />
            <PublicRoute
              exact
              path={routerPaths.myAgent.myAgent}
              component={AboutAgent}
            />
            <Route exact path={'/'}>
              <Redirect to={routerPaths.myHome.myHome} />
            </Route>
            <Route exact path={'/my-agent/chat'}>
              <Redirect
                to={{
                  pathname: routerPaths.myHome.myHome,
                  search: `${STATIC_CONFIG.MY_AGENT_CHAT_REDIRECT_PARAM}`,
                }}
              />
            </Route>

            <Route exact path="/login">
              <Redirect to={'/'} />
            </Route>

            <PublicRoute
              exact
              path={routerPaths.myHome.myHome}
              component={MyHomePage}
            />
            <PublicRoute
              exact
              path={routerPaths.myHome.myHomeMarket}
              component={MyHomeMarketPage}
            />
            <PublicRoute
              exact
              path={routerPaths.myHome.myMarkets}
              component={MyMarketsPage}
            />
            {hasNotificationsFlag && isMobile && (
              <PublicRoute
                exact
                path={routerPaths.notifications}
                component={NotificationsPage}
              />
            )}

            <PublicRoute
              exact
              path={routerPaths.myJourney.myMove}
              component={MyMovePage}
            />

            <PublicRoute
              exact
              path={routerPaths.myJourney.myTransactions}
              component={MyTransactionPage}
            />

            <PublicRoute
              exact
              path={routerPaths.myJourney.myMaintenance}
              component={MyMaintenancePage}
            />

            <PublicRoute
              exact
              path={routerPaths.myJourney.movingInventory}
              component={MovingInventory}
            />

            <PublicRoute
              exact
              path={routerPaths.myJourney.myMoveEssentialQuestions}
              component={EssentialQuestions}
            />

            <PublicRoute
              exact
              path={routerPaths.nextHome.listingDetail}
              component={ListingDetail}
            />
            <PublicRoute
              exact
              path={routerPaths.nextHome.listing}
              component={ListingDetail}
            />
            <PublicRoute
              exact
              path={routerPaths.nextHome.marketDetail}
              component={MarketDetailPage}
            />

            <PublicRoute
              exact
              path={routerPaths.search.search}
              component={Search}
            />
            <PublicRoute
              exact
              path={routerPaths.search.searchResults}
              component={SearchResults}
            />

            <PublicRoute
              exact
              path={routerPaths.savedHomes}
              component={SavedHomes}
            />

            <PublicRoute exact path={routerPaths.faq} component={FAQPage} />
            <PublicRoute path="*" component={PageNotFoundPage} />
          </Switch>
        </Suspense>
      </KnownUserLayout>
    </VisitedRoutesProvider>
  );
};
