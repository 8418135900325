import React, { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ThemeProvider, useTheme } from '@emotion/react';

import S from './FAQPage.styles';
import getContent from './content';
import { Contact } from './components/Contact';
import { BrandingDetailsExtended } from '../../types';
import { Page } from '../../components/V2/layout/Page/Page';
import { routerPaths, theme as buildTheme } from '../../constants';
import { GlobalStyling } from '../../components/global-style/global-style.style';
import { ExpandableSection } from '../ListingDetails/components/ListingDetailsContent/ExpandableSection/ExpandableSection';

import { useSetDocumentTitle } from '../../hooks/useSetDocumentTitle';
import { useTrackPage, TrackPageTypes } from '../../features/analytics';
import { useBrandingAndAgent } from '../MobileAppDeeplink/hooks/useBrandingAndAgent';

const PublicWrapper: React.FC = ({ children }) => {
  const { data } = useBrandingAndAgent();

  const theme = useMemo(() => {
    if (!data) return;

    let themeColorHex = data?.brandingColor ?? '#000';
    if (themeColorHex && !themeColorHex.startsWith('#')) {
      themeColorHex = `#${data?.brandingColor}`;
    }

    return buildTheme({ themeColorHex } as BrandingDetailsExtended);
  }, [data]);

  if (!theme) return null;

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

const PrivateWrapper: React.FC = ({ children }) => (
  <Page hideLicenceAndPrivacy hideMlsDisclaimers>
    {children}
  </Page>
);

export const FAQPage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  useSetDocumentTitle('pageTitles.faq');

  const { pathname } = useLocation();
  const isPublic = pathname !== routerPaths.faq;

  const [expanded, setExpanded] = useState('');
  const [expandedGroupItem, setExpandedGroupItem] = useState('');
  const [expandedGroupParent, setExpandedGroupParent] = useState('');

  const themeColor = theme?.colors?.branding?.extraLight || '#fff';
  const contents = getContent(t);
  const toggleVal = (current: string, nextVal: string) => {
    if (current === nextVal) return '';
    return nextVal;
  };
  useTrackPage(TrackPageTypes.menuHelp);

  const renderContents = () => {
    return contents.map((contentItem, i) => {
      if (!contentItem.groups) {
        return (
          <ExpandableSection
            key={contentItem.id || i}
            title={contentItem.title || ''}
            id={contentItem.id || ''}
            isOpen={expanded === contentItem.id}
            onToggle={() => setExpanded(toggleVal(expanded, contentItem.id))}
            noContentPadding
          >
            {contentItem?.list?.map((item) => {
              if (item?.exclude) return null;

              return (
                <S.ContentGroupListItem key={item.question}>
                  <h5>{item.question}</h5>

                  <p style={{ whiteSpace: 'pre-wrap' }}>{item.answer}</p>
                </S.ContentGroupListItem>
              );
            })}
          </ExpandableSection>
        );
      }

      return (
        <S.ContentGroup key={i + 'a'}>
          <ExpandableSection
            title={contentItem.title || ''}
            id={contentItem.id || ''}
            isOpen={expandedGroupParent === contentItem.id}
            onToggle={() => {
              setExpandedGroupParent(
                toggleVal(expandedGroupParent, contentItem.id),
              );
              setExpandedGroupItem('');
            }}
            noContentPadding
            headStyles={{
              backgroundColor:
                expandedGroupParent === contentItem.id ? themeColor : '#fff',
              borderRadius: 'none',
            }}
            containerStyles={{
              borderRadius: 0,
              boxShadow: 'none',
              border: 'none',
            }}
          />
          <div
            style={{
              display: expandedGroupParent === contentItem.id ? 'flex' : 'none',
              flexDirection: 'column',
              gap: 2,
              marginTop: 2,
            }}
          >
            {contentItem.groups &&
              contentItem.groups.map((groupItem, j) =>
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                groupItem.exclude ? null : (
                  <ExpandableSection
                    key={j + groupItem.title}
                    title={groupItem.title || ''}
                    id={groupItem.id || ''}
                    isOpen={expandedGroupItem === groupItem.id}
                    onToggle={() =>
                      setExpandedGroupItem(
                        toggleVal(expandedGroupItem, groupItem.id),
                      )
                    }
                    headStyles={{
                      background: '#e6e6e6',
                      borderRadius: 0,
                      paddingLeft: 24,
                    }}
                    containerStyles={{
                      borderRadius: 0,
                      boxShadow: 'none',
                      border: 'none',
                    }}
                    noContentPadding
                  >
                    {groupItem.list &&
                      groupItem.list.map((item, i) =>
                        item.exclude ? null : (
                          <S.ContentGroupListItem
                            key={i}
                            style={{ paddingLeft: 60 }}
                          >
                            <h5>{item.question}</h5>
                            <p style={{ whiteSpace: 'pre-wrap' }}>
                              {item.answer}
                            </p>
                          </S.ContentGroupListItem>
                        ),
                      )}
                  </ExpandableSection>
                ),
              )}
          </div>
        </S.ContentGroup>
      );
    });
  };

  const Wrapper = !isPublic ? PrivateWrapper : PublicWrapper;

  return (
    <Wrapper>
      <GlobalStyling />
      <S.Container>
        <S.BG />
        <S.GreetingContainer>
          <S.Title>
            <span>?</span>
            {t('faq.title')}
            <span>{t('faq.subtitle')}</span>
          </S.Title>
        </S.GreetingContainer>
        <S.ContentContainer>
          <S.ContentSection>{isPublic ? null : <Contact />}</S.ContentSection>
          <S.ContentSection>{renderContents()}</S.ContentSection>
        </S.ContentContainer>
      </S.Container>
    </Wrapper>
  );
};
