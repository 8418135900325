import { useMemo } from 'react';

import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { routerPaths } from '../../../../../constants';
import { useHasAreasOfInterest } from './useHasAreasOfInterest';
import appNavAtom from '../../../../../recoil/appNav/appNavAtom';
import { useIsMobileView } from '../../../../../hooks/useIsMobileView';
import { useSavedListings } from '../../../../../hooks/query/saveListing/useSavedListings';
import { SubOrigin } from '../../../../analytics';
import { getSearchResultsRouteParams } from '../../../../../utils/getSearchResultsRouteParams';
import useDefaultSearchArea from '../../../../../hooks/query/useDefaultSearchArea';

export function useSubNavItems() {
  const { t } = useTranslation();
  const isMobile = useIsMobileView();
  const isSubNavHidden = useRecoilValue(appNavAtom);
  const hasAreasOfInterest = useHasAreasOfInterest();
  const { data: savedListings } = useSavedListings();
  const { areaId, polygonId, countrySubd } = useDefaultSearchArea();

  const hasSavedHomes = useMemo(
    () => Boolean(savedListings?.length),
    [savedListings?.length],
  );

  return useMemo(
    () => ({
      ['my-home']: [
        {
          navGroupPaths: {
            myHome: routerPaths.myHome.myHome,
          },
          route: routerPaths.myHome.myHome,
          label: t('myHome.subheaders.valueAndEquity'),
          isGated: false,
          isHidden: false,
          origin: SubOrigin.myHome,
        },
        {
          navGroupPaths: { maintenance: routerPaths.myJourney.myMaintenance },
          route: routerPaths.myJourney.myMaintenance,
          label: t('myHome.subheaders.maintenance'),
          isGated: false,
          isHidden: false,
          origin: SubOrigin.maintenance,
        },
        {
          navGroupPaths: {
            myMarkets: routerPaths.myHome.myMarkets,
            myHomeMarket: routerPaths.myHome.myHomeMarket,
            marketDetail: routerPaths.nextHome.marketDetail,
          },
          route: hasAreasOfInterest
            ? routerPaths.myHome.myMarkets
            : routerPaths.myHome.myHomeMarket,
          label: t(
            `myHome.subheaders.myMarket.${isMobile ? 'mobile' : 'desktop'}`,
          ),
          isGated: false,
          isHidden: false,
          origin: SubOrigin.marketTrends,
        },
      ],
      ['search']: [
        {
          navGroupPaths: {
            ...routerPaths.search,
            listings: routerPaths.nextHome.listing,
            listing: routerPaths.nextHome.listingDetail,
          },
          route: isMobile
            ? getSearchResultsRouteParams({
                areaId,
                polygonId,
                countrySubd,
                areaType: 'locality',
              })
            : routerPaths.search.search,
          label: t('search.subheaders.listings'),
          isGated: false,
          isHidden: false,
          origin: SubOrigin.listings,
        },
        {
          navGroupPaths: isSubNavHidden
            ? {}
            : { favorites: routerPaths.savedHomes },
          route: routerPaths.savedHomes,
          label: t('search.subheaders.favorites'),
          isGated: false,
          isHidden: !hasSavedHomes,
          origin: SubOrigin.favorites,
        },
        {
          navGroupPaths: {
            transactions: routerPaths.myJourney.myTransactions,
          },
          route: routerPaths.myJourney.myTransactions,
          label: t('search.subheaders.transaction'),
          isGated: false,
          isHidden: false,
          origin: SubOrigin.myTransactions,
        },
        {
          navGroupPaths: {
            move: routerPaths.myJourney.myMove,
          },
          route: routerPaths.myJourney.myMove,
          label: t('search.subheaders.move'),
          isGated: false,
          isHidden: false,
          origin: SubOrigin.myMove,
        },
      ],
    }),
    [
      t,
      hasAreasOfInterest,
      isMobile,
      areaId,
      polygonId,
      countrySubd,
      isSubNavHidden,
      hasSavedHomes,
    ],
  );
}
