import styled from '@emotion/styled';
import { Drawer } from '@mui/material';

export const SideMenuContainer = styled(Drawer, {
  shouldForwardProp: (prop) =>
    !['hiddenMobileBanner', 'isMobile'].includes(prop),
})<{
  hiddenMobileBanner: boolean;
  isMobile: boolean;
}>`
  & .MuiPaper-root {
    padding-top: 15rem;
    width: ${({ isMobile }) => (isMobile ? '100%' : '355px')};
    background: ${({ theme }) => theme.colors.white};
    position: relative;
    height: ${({ isMobile }) => (isMobile ? '100vh' : '100%')};
    padding-bottom: ${({ isMobile }) => (isMobile ? '10rem' : '')};
  }

  @media (max-width: ${({ theme }) => theme.breakpoint.md.max}) {
    padding-top: ${({ hiddenMobileBanner }) =>
      hiddenMobileBanner ? '0' : '17.5rem'};
    padding-bottom: 9rem;
  }

  &.MuiDrawer-root.MuiDrawer-modal.MuiModal-root[role='presentation'] {
    overflow: scroll;

    @media (max-width: ${({ theme }) => theme.breakpoint.md.max}) {
      height: 105%;
    }
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0;
  align-self: flex-start;
`;

export const NavLink = styled.a<{
  isBold?: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 1.6rem 2.4rem;
  position: relative;
  color: ${({ theme }) => theme.colors.black};
  font-weight: ${({ theme, isBold }) =>
    isBold ? theme.fontWeight.bold : theme.fontWeight.medium};
  &:hover {
    color: ${({ theme }) => theme.colors.black};
    cursor: pointer;
  }
`;

export const NavLinkGrey = styled(NavLink)<{
  hasMt?: boolean;
  isBold?: boolean;
}>`
  color: ${({ theme }) => theme.colors.grey.dark};
  margin-top: ${({ hasMt }) => hasMt && '2.5rem'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 1.6rem 2.4rem;
  position: relative;
  font-weight: ${({ theme, isBold }) =>
    isBold ? theme.fontWeight.bold : theme.fontWeight.medium};
  &:hover {
    color: ${({ theme }) => theme.colors.grey.dark};
    cursor: pointer;
  }
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const IconWrapper = styled.div`
  & img {
    fill: #007bff;
    flex-shrink: 0;
    align-self: flex-start;
    position: absolute;
    right: 20px;
    top: 12px;
    width: 22px;
    height: 22px;
  }
`;

export const QuestionMark = styled('span')(({ theme }) => ({
  position: 'absolute',
  right: 20,
  top: 12,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 16,
  borderRadius: '50%',
  border: `1px solid ${theme.colors.branding.main}`,
  color: theme.colors.branding.main,
  width: 22,
  height: 22,
}));
